@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html,
body {
  margin: 0;
  font-family:
    'Pretendard Variable',
    'Montserrat',
    'Times New Roman',
    Times,
    serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.025em;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

code {
  font-family: 'Pretendard Variable', 'Montserrat', 'Times New Roman',
    source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button > * {
  cursor: pointer;
}

/* 아이폰에서 border 생겼다가 사라지는 이슈때문에 추가. */
video {
  display: block;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

/* 오디오 비주얼라이저저 스타일 */
.audio-visualizer li {
  list-style: none;
  background: #fff;
  width: 5px;
  height: 5px;
  margin: 0 5px;
  animation: bounce 1s infinite ease-in-out;
  animation-play-state: paused;
}
.audio-visualizer.playing li {
  animation-play-state: running;
}
.audio-visualizer li:nth-child(1) {
  animation-delay: -0.4s;
}

.audio-visualizer li:nth-child(2) {
  animation-delay: -0.2s;
}

.audio-visualizer li:nth-child(3) {
  animation-delay: 0s;
}

.audio-visualizer li:nth-child(4) {
  animation-delay: 0.2s;
}

.audio-visualizer li:nth-child(5) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}

/* 메인 스와이퍼 스타일 */
.reviewSwiper .swiper-wrapper {
  align-items: stretch;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
}
.pagination ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 4px;
}
.pagination ul li {
  width: 100px;
  background-color: #11111133;
  height: 4px;
  position: relative;
}
.pagination.mobile ul li {
  width: calc((100%-12) / 4);
  background-color: #11111133;
  height: 4px;
  position: relative;
}

.pagination ul li.active .progress-bar {
  background-color: #222;
  animation: progress 3.5s linear infinite;
}
.pagination ul li .progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* 캐릭터 페이지 격자무늬 */
/* .check-gradient-background-vertical {
  background: linear-gradient(
    to right,
    transparent calc((100vw - 40px) / 10 - 1px),
    #f4f4f4 calc((100vw - 40px) / 10 - 1px)
  );
  background-size: calc((100vw - 40px) / 10) 100%;
  background-repeat: repeat-x;
}

.check-gradient-background-horizontal {
  background: linear-gradient(to bottom, transparent 98%, #f4f4f4 20%);
  background-size: 100% 10%;
  background-repeat: repeat-y;
} */
/* .check-gradient-background {
  background:
    linear-gradient(to bottom, transparent 98%, #f4f4f4 20%) 0 0 / 100% 10%
      repeat-y,
    linear-gradient(
        to right,
        transparent calc((100vw - 40px) / 10 - 1px),
        #f4f4f4 calc((100vw - 40px) / 10 - 1px)
      )
      0 0 / calc((100vw - 40px) / 10) 100% repeat-x,
    white;
} */
.check-gradient-background {
  background:
    linear-gradient(
        to bottom,
        transparent calc((100vw - 40px) / 10 - 1px),
        #f4f4f4 calc((100vw - 40px) / 10 - 1px)
      )
      0 0 / 100% calc((100vw - 40px) / 10) repeat-y,
    linear-gradient(to right, transparent 98%, #f4f4f4 20%) 0 0 / 10% 100%
      repeat-x,
    white;
}
